<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link
          class="brand-logo"
          :to="{name:'accueil'}"
        >
          <b-img
            :src="appLogoImage"
            width="40"
            height="40"
            alt="logo"
          />

          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Email de vérification
        </b-card-title>
        <b-card-text class="mb-2">
          Merci de vous être inscrit(e) ! Avant de commencer, veuillez vérifier votre adresse email en cliquant sur le lien que nous venons de vous envoyer. Si vous n'avez pas reçu cet email, nous vous en enverrons un nouveau avec plaisir.
        </b-card-text>
        <b-row>
          <b-col md="7">
            <b-form
              class="auth-reset-password-form  "
              @submit.prevent="resend"
            >
              <!-- submit button -->
              <b-button
                type="submit"
                variant="primary"
                size="sm"
              >
                Renvoyer l'email
                <b-spinner
                  v-if="process"
                  small
                  class="ml-1"
                  label="Loading..."
                  variant="light"
                />
              </b-button>
            </b-form>
          </b-col>
          <b-col
            md="5"
          >
            <b-button
              variant="danger"
              size="sm"
              @click.prevent="logout"
            >
              Déconnexion
              <!-- <b-spinner
                v-if="process"
                small
                class="ml-1"
                label="Loading..."
                variant="light"
              /> -->
            </b-button>
          </b-col>
        </b-row>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>

import {
  BCard, BCardTitle, BSpinner, BImg, BCardText, BRow, BCol, BForm, BLink, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useAuthenticate from '@/services/authentification/authService'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BSpinner,
    BRow,
    BCol,
    BLink,
  },

  setup() {
    const {
      process, resendVerificationMail, logout,
    } = useAuthenticate()
    const { appName, appLogoImage } = $themeConfig.app

    const resend = async () => {
      await resendVerificationMail()
    }
    return {
      resend,
      process,
      logout,
      appName,
      appLogoImage,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
